export const categories = [
  {
    id: 1,
    img: "towels-linens-store-bath-linen.jpg",
    title: "450 GMS TOWEL!",
    gridname: "f",
    weight: "450 GMS TOWEL",
    name: "FAIROUZ",
  },
  {
    id: 2,
    img: "Stack-of-clean-soft-colorful-towels-Flowers-on-background.jpg",
    title: "550 GMS TOWEL!",
    gridname: "h",
    weight: "550 GMS TOWEL",
    name: "FAIROUZ",
  },
  {
    id: 3,
    img: "towels-linen-house-bathroom.jpg",
    title: "600 GMS TOWEL!",
    gridname: "y",
    weight: "600 GMS TOWEL",
    name: "YOUMNA",
  },
  {
    id: 5,
    img: "5134588.jpg",
    title: "450 GMS WHITE!",
    gridname: "fw",
    weight: "450 GMS WHITE TOWEL",
    name: "FAIROUZ",
  },
  {
    id: 6,
    img: "Kensington-White.jpg",
    title: "600 GMS WHITE!",
    gridname: "fh",
    weight: "600 GMS WHITE TOWEL",
    name: "YOUMNA",
  },
];

export const exports = [
  {
    id: 1,
    name: "SAUDI ARABIA",
    img: "6ad61842-a719-4e55-96ab-c2e2786c233b-16x9-1200x676.webp",
    code: "sa",
  },
  {
    id: 2,
    name: "LIBYA",
    img: "e103c4d6ec43661fd1561de4cd8d0799-700.jpg",
    code: "ly",
  },
  {
    id: 3,
    name: "GHANA",
    img: "A-drone-footage-of-Accra-central-Ghana.jpg",
    code: "gh",
  },

  {
    id: 4,
    name: "ITALY",
    img: "Positano-Weather.webp",
    code: "it",
  },
  {
    id: 5,
    name: "AMSTERDAM",
    img: "The-illiminating-city-of-Amsterdam-and-its-boat-filled-canal-580x345.jpg",
    code: "nl",
  },
  {
    id: 6,
    name: "PALESTINE",
    img: "image1170x530cropped.jpg",
    code: "ps",
  },
  {
    id: 7,
    name: "SUDAN",
    img: "8b262f3d52b3f4e8024b7b1eece41671.jpg",
    code: "sd",
  },
  {
    id: 8,
    name: "LEBANON",
    img: "photo-1521327895744-46e309d005b4.jpeg",
    code: "lb",
  },
  {
    id: 9,
    name: "JORDAN",
    img: "Cover-for-Attractions-In-Jordan.webp",
    code: "jo",
  },
];

export const PromisesList = [
  {
    id: 1,
    detail: "47 years of experience in towels industry",
    num: "fas fa-duotone fa-trophy",
    info: "We are lead the towels industry since 1975 with great potential and high stability",
  },
  {
    id: 2,
    detail: "+200 employees and workers",
    num: "fas fa-users",
    info: "Our employees and workers dedicated to working to the maximum, eager to produce high quality products",
  },
  {
    id: 3,
    detail: "ISO 9001 certificate",
    num: "fas fa-certificate",
    info: "We are a company with ISO9001:2008 international QMS certification",
  },
  {
    id: 4,
    detail: "+300 active clients",
    num: "fas fa-user-tie",
    info: "We served a lot of big companies and have and we have a large client base",
  },
  {
    id: 5,
    detail: "High quality towels",
    num: "far fa-copyright",
    info: "We promise our client with high quality towel that can not be replaced",
  },
  {
    id: 6,
    detail: "We care about time",
    num: "fas fa-clock	",
    info: "one of our priorities is to pay attention to perfection and delivery on time",
  },
];

export const popularProducts = [
  {
    id: 1,
    img: "https://i.ibb.co/wN10fpk/14.png",
    category: "FAIROUZ",
    weight: "450 GMS TOWEL",
    size: "30 x 55 cm",
    score: 1650,
    material: "100% egyption cotton",
    type: "hand towel",
    description:
      " towel hand measuring 33x55 cm. These small size towel hand are made up of 100% egyption cotton which ensures long lasting durability and reliability EXTRA SOFT AND ABSORBENT fahmy younes towels are made using high quality natural cotton. The cotton fibers utilized ensure optimum softness, fluffiness and wonderful absorbency. They get fluffier and softer with each wash.",
    ci: [
      {
        color: "ligth blue",
        image: "https://i.ibb.co/wN10fpk/14.png",
        index: 0,
      },
      {
        color: "light orange",
        image: "https://i.ibb.co/7r5sd8W/13.png",
        index: 1,
      },
      {
        color: "dark orange",
        image: "https://i.ibb.co/yXtv66C/12.png",
        index: 2,
      },
      {
        color: "pink",
        image: "https://i.ibb.co/86vNbmS/10.png",
        index: 3,
      },
      {
        color: "lemon",
        image: "https://i.ibb.co/bdv4bTP/9.png",
        index: 4,
      },
      {
        color: "brown",
        image: "https://i.ibb.co/RDkn6xR/8.png",
        index: 5,
      },
      {
        color: "dark pink",
        image: "https://i.ibb.co/t4VW5G1/6.png",
        index: 6,
      },
      {
        color: "beig",
        image: "https://i.ibb.co/N7m9q13/5.png",
        index: 7,
      },
      {
        color: "black",
        image: "https://i.ibb.co/2kTSXGZ/4.png",
        index: 8,
      },
      {
        color: "yellow",
        image: "https://i.ibb.co/rGvyy0Z/3.png",
        index: 9,
      },
      {
        color: "soft green",
        image: "https://i.ibb.co/kDmbFTX/2.png",
        index: 10,
      },
      {
        color: "purble",
        image: "https://i.ibb.co/MZhQFmV/image.png",
        index: 11,
      },
    ],
  },
  {
    id: 2,
    img: "https://i.ibb.co/wN10fpk/14.png",
    category: "FAIROUZ",
    weight: "450 GMS TOWEL",
    size: "30 x 55 cm",
    score: 1650,
    material: "100% egyption cotton",
    type: "hand towel",
    description:
      " towel hand measuring 33x55 cm. These small size towel hand are made up of 100% egyption cotton which ensures long lasting durability and reliability EXTRA SOFT AND ABSORBENT fahmy younes towels are made using high quality natural cotton. The cotton fibers utilized ensure optimum softness, fluffiness and wonderful absorbency. They get fluffier and softer with each wash.",
    ci: [
      {
        color: "ligth blue",
        image: "https://i.ibb.co/wN10fpk/14.png",
        index: 0,
      },
      {
        color: "light orange",
        image: "https://i.ibb.co/7r5sd8W/13.png",
        index: 1,
      },
      {
        color: "dark orange",
        image: "https://i.ibb.co/yXtv66C/12.png",
        index: 2,
      },
      {
        color: "pink",
        image: "https://i.ibb.co/86vNbmS/10.png",
        index: 3,
      },
      {
        color: "lemon",
        image: "https://i.ibb.co/bdv4bTP/9.png",
        index: 4,
      },
      {
        color: "brown",
        image: "https://i.ibb.co/RDkn6xR/8.png",
        index: 5,
      },
      {
        color: "dark pink",
        image: "https://i.ibb.co/t4VW5G1/6.png",
        index: 6,
      },
      {
        color: "beig",
        image: "https://i.ibb.co/N7m9q13/5.png",
        index: 7,
      },
      {
        color: "black",
        image: "https://i.ibb.co/2kTSXGZ/4.png",
        index: 8,
      },
      {
        color: "yellow",
        image: "https://i.ibb.co/rGvyy0Z/3.png",
        index: 9,
      },
      {
        color: "soft green",
        image: "https://i.ibb.co/kDmbFTX/2.png",
        index: 10,
      },
      {
        color: "purble",
        image: "https://i.ibb.co/MZhQFmV/image.png",
        index: 11,
      },
    ],
  },
  {
    id: 3,
    img: "https://i.ibb.co/wN10fpk/14.png",
    category: "FAIROUZ",
    weight: "450 GMS TOWEL",
    size: "30 x 55 cm",
    score: 1650,
    material: "100% egyption cotton",
    type: "hand towel",
    description:
      " towel hand measuring 33x55 cm. These small size towel hand are made up of 100% egyption cotton which ensures long lasting durability and reliability EXTRA SOFT AND ABSORBENT fahmy younes towels are made using high quality natural cotton. The cotton fibers utilized ensure optimum softness, fluffiness and wonderful absorbency. They get fluffier and softer with each wash.",
    ci: [
      {
        color: "ligth blue",
        image: "https://i.ibb.co/wN10fpk/14.png",
        index: 0,
      },
      {
        color: "light orange",
        image: "https://i.ibb.co/7r5sd8W/13.png",
        index: 1,
      },
      {
        color: "dark orange",
        image: "https://i.ibb.co/yXtv66C/12.png",
        index: 2,
      },
      {
        color: "pink",
        image: "https://i.ibb.co/86vNbmS/10.png",
        index: 3,
      },
      {
        color: "lemon",
        image: "https://i.ibb.co/bdv4bTP/9.png",
        index: 4,
      },
      {
        color: "brown",
        image: "https://i.ibb.co/RDkn6xR/8.png",
        index: 5,
      },
      {
        color: "dark pink",
        image: "https://i.ibb.co/t4VW5G1/6.png",
        index: 6,
      },
      {
        color: "beig",
        image: "https://i.ibb.co/N7m9q13/5.png",
        index: 7,
      },
      {
        color: "black",
        image: "https://i.ibb.co/2kTSXGZ/4.png",
        index: 8,
      },
      {
        color: "yellow",
        image: "https://i.ibb.co/rGvyy0Z/3.png",
        index: 9,
      },
      {
        color: "soft green",
        image: "https://i.ibb.co/kDmbFTX/2.png",
        index: 10,
      },
      {
        color: "purble",
        image: "https://i.ibb.co/MZhQFmV/image.png",
        index: 11,
      },
    ],
  },
];
//export const API = "http://localhost:5000";
export const API = "https://fahmyapi.mo-imperial.work";

export const objectToQueryParams = (obj) => {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        obj[key] !== undefined &&
        obj[key] !== "" &&
        (!Array.isArray(obj[key]) ||
          (Array.isArray(obj[key]) && obj[key].length > 0))
      ) {
        if (Array.isArray(obj[key])) {
          params.append(key, obj[key].join(","));
        } else {
          params.append(key, obj[key]);
        }
      }
    }
  }
  return params.toString();
};
