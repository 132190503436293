import React, { useEffect, useState } from "react";
import * as com from "./styledComponents";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ExpandMore, ArrowLeft } from "@material-ui/icons";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import Products from "./Products";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API, objectToQueryParams } from "../data";
import { mobile, tablet, laptop } from "../responsive";
import Ex from "../Loading";
import { useTranslation } from "react-i18next";
const FilterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  ${laptop({
    flexDirection: "column",
    alignItems: "center",
  })}
  ${tablet({
    flexDirection: "column",
    alignItems: "center",
  })}
  ${mobile({
    flexDirection: "column",
    alignItems: "center",
  })}
`;

const GoBackLink = styled.div`
  display: flex;
  width: 100%;
  text-decoration: none;
  color: #000b49;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
`;
const GoBack = styled.div`
  display: flex;
`;

const Filter = styled.div`
  margin: 20px;
`;

const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
`;
const Option = styled.option``;
const LoadingDiv = styled.div`
  flex: 5;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;
const NoData = styled.div`
  width: 100%;
  height: 50vh;
  display: felx;
  justify-content: center;
  align-items: center;
`;

const Msg = styled.p`
  font-weight: bold;
  font-size: 2em;
`;

const getMsg = async (query) => {
  const response = await axios.get(API + `/towel/?${query}`);
  return response.data;
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CategoryContainer = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  //let navigate = useNavigate();
  const [List, setList] = useState([]);
  const [Loading, setLoading] = useState(true);
  const query = useQuery();
  const [Disabled, setDisabled] = useState(query.get("q") ? true : false);
  const [filter, setFilter] = useState({
    size: [],
    type: query.get("type") || [],
    order: query.get("order") || "asc",
    weight: query.get("weight") || "",
    search: query.get("search") || "",
  });

  useEffect(() => {
    const newQuery = objectToQueryParams(filter);
    getMsg(newQuery).then((payload) => {
      setList(payload?.towels);
      setLoading(false);
    });
  }, [filter]);

  const HandleChangeScore = (e) => {
    setFilter({ ...filter, order: e.target.value });
  };

  const handleChangeCheck = (e) => {
    const key = e.target.dataset.key;
    const value = e.target.name;
    const contains = filter[key].filter((val) => val === value);
    if (!e.target.checked && contains.length) {
      const obj = { ...filter };
      obj[key] = obj[key].filter((val) => val != value);
      setFilter({ ...obj });
    } else {
      setFilter({ ...filter, [key]: [...filter[key], value] });
    }
  };

  const Content = () => {
    if (Loading) {
      return (
        <LoadingDiv>
          <Ex />
        </LoadingDiv>
      );
    } else {
      if (List.length > 0) {
        return (
          <Products lst={List} search={!!query.get("search")} color={query.get("search") ? query.get("search") : ""} />
        );
      } else {
        return (
          <NoData>
            <Msg>{t("No data to show")}</Msg>
          </NoData>
        );
      }
    }
  };
  return (
    <com.Container>
      <com.Wrapper>
        {!query.get("search") && (
          <com.Filter>
            <com.FilterTitle>{t("Filter Towels")} : </com.FilterTitle>

            {!query.get("type") && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{t("Towel Type")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    <FormControlLabel
                      disabled={Disabled ? true : false}
                      onChange={handleChangeCheck}
                      control={<Checkbox inputProps={{ "data-key": "type" }} />}
                      label="Geust Towel"
                      name="geust towel"
                    />
                    <FormControlLabel
                      disabled={Disabled ? true : false}
                      onChange={handleChangeCheck}
                      control={<Checkbox inputProps={{ "data-key": "type" }} />}
                      label="Hand Towel"
                      name="hand towel"
                    />
                    <FormControlLabel
                      disabled={Disabled ? true : false}
                      onChange={handleChangeCheck}
                      control={<Checkbox inputProps={{ "data-key": "type" }} />}
                      label="Bath Towel"
                      name="bath towel"
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>{t("Towel Size")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControlLabel
                    disabled={Disabled ? true : false}
                    onChange={handleChangeCheck}
                    control={<Checkbox inputProps={{ "data-key": "size" }} />}
                    label="33×33 cm"
                    name={`${33 * 33}`}
                  />
                  <FormControlLabel
                    disabled={Disabled ? true : false}
                    onChange={handleChangeCheck}
                    control={<Checkbox inputProps={{ "data-key": "size" }} />}
                    label="33×55 cm"
                    name={`${33 * 55}`}
                  />
                  <FormControlLabel
                    disabled={Disabled ? true : false}
                    onChange={handleChangeCheck}
                    control={<Checkbox inputProps={{ "data-key": "size" }} />}
                    label="40×60 cm"
                    name={`${40 * 60}`}
                  />
                  <FormControlLabel
                    disabled={Disabled ? true : false}
                    onChange={handleChangeCheck}
                    control={<Checkbox inputProps={{ "data-key": "size" }} />}
                    label="50×90 cm"
                    name={`${50 * 90}`}
                  />
                  <FormControlLabel
                    disabled={Disabled ? true : false}
                    onChange={handleChangeCheck}
                    control={<Checkbox inputProps={{ "data-key": "size" }} />}
                    label="50×100 cm"
                    name={`${50 * 100}`}
                  />

                  <FormControlLabel
                    disabled={Disabled ? true : false}
                    onChange={handleChangeCheck}
                    control={<Checkbox inputProps={{ "data-key": "size" }} />}
                    label="60×120 cm"
                    name={`${60 * 120}`}
                  />
                  <FormControlLabel
                    disabled={Disabled ? true : false}
                    onChange={handleChangeCheck}
                    control={<Checkbox inputProps={{ "data-key": "size" }} />}
                    label="70×140 cm"
                    name={`${70 * 140}`}
                  />
                  <FormControlLabel
                    disabled={Disabled ? true : false}
                    onChange={handleChangeCheck}
                    control={<Checkbox inputProps={{ "data-key": "size" }} />}
                    label="90×180 cm"
                    name={`${90 * 180}`}
                  />
                  <FormControlLabel
                    disabled={Disabled ? true : false}
                    onChange={handleChangeCheck}
                    control={<Checkbox inputProps={{ "data-key": "size" }} />}
                    label="60x110 cm"
                    name={`${60 * 110}`}
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </com.Filter>
        )}

        <com.Products>
          <FilterContainer>
            <GoBack>
              <GoBackLink>
                <ArrowLeft
                  style={{
                    fontSize: "40px",
                    marginRight: "15px;",
                    order: lang == "ar" ? "2" : "1",
                  }}
                />
                <a href={"/#cat"} style={{ order: lang == "ar" ? "1" : "2" }}>
                  {" "}
                  {t("Go back to categories")}{" "}
                </a>
              </GoBackLink>
            </GoBack>
            <h1>
              {query.get("weight")
                ? t(query.get("weight") + "!")
                : query.get("q")}
            </h1>
            <Filter>
              <FilterText>{t("Sort Products")}:</FilterText>
              <Select onChange={HandleChangeScore}>
                <Option value="asc">({t("asc")})</Option>
                <Option value="desc">({t("desc")})</Option>
              </Select>
            </Filter>
          </FilterContainer>
          {Content()}
        </com.Products>
      </com.Wrapper>
    </com.Container>
  );
};

export default CategoryContainer;
